h1,
h2,
h3,
h4,
h5 {
    font-family: 'skufont-demibold', "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !important;
}

@media screen and (max-width: 640px) {
    body #root {
        background-color: #fff !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        text-align: center;
    }

    .login-content {
        display: block;
    }

    .contentBoxContainer {
        display: block;
        background: none;
        color: white;
        gap: 0 !important;
    }

    .contentBoxContainer .topBox {
        width: 100%;
    }

    .contentBoxContainer .bottomBox {
        width: 100%;
        padding: 36px !important;
        background-color: #02C0DA;
    }
}

@media screen and (min-width: 641px) {
    body #root {
        background-color: #EDF4F7 !important;
    }

    .login-content {
        margin-top: 95px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .login-content-center {
        max-width: 1280px;
    }

    .right-side-content {
        margin: 36px !important;
        margin-left: 0px !important;
    }

    .left-side-content {
        margin: 36px !important;
        margin-right: 0px !important;
    }

    .contentBoxContainer {
        margin: 20px;
        height: 48%;
        min-height: 535px;
        display: flex;
        flex-direction: column;
        background: #EDF4F7 !important;
        color: white;
        gap: 29px !important;
    }

    .contentBoxContainer .topBox {
        width: 100%;
    }

    .contentBoxContainer .bottomBox {
        width: 100%;
        flex: 1;
        padding: 36px !important;
        background-color: #02C0DA;
    }
}

.login-lft {
    position: absolute;
    left: 0;
    top: 0;
    float: left;
    padding: 20px !important;
}

.login-lft img {
    height: 55px !important;
}

.login-right {
    position: absolute;
    right: 0;
    top: 0;
    float: right;
    padding: 20px !important;
}

.login-sticky-container {
    position: relative;
    z-index: 1;
    height: 95px;
    background-color: white;
}

.login-header {
    position: absolute;
    height: 95px;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: transparent
}

.sideContent {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, white 0%, white 100%);
    background-image: url(/images/login-background.png);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 29px;
    display: inline-flex;
}

.sideContent .sideContentHeader {
    width: 100%;
    color: white;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    word-wrap: break-word;
}

.contentBox {
    margin: 20px;
    height: 48%;
    min-height: 535px;
    padding: 36px !important;
    color: white;
}